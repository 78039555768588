.adai_fire_wrap{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
}
.adai_fire_wrap canvas{
    width: 100%;
    height: 100%;
    display: block;
    border: none;
}