.taskInfo {
  width: 100%;
  height: 100%;
  /* position: fixed;
  top: 65px;
  right: 0px; */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* z-index: 50; */
  /* background-color: #fff; */
  color: #333;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
  box-sizing: border-box;
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); */
  /* border-radius: 12px; */
}

.taskInfo-mainTitle {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
  z-index: 2;
}

.taskInfo-mainTitle-left,
.taskInfo-mainTitle-left-info {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  position: relative;
}

.taskInfo-mainTitle-left-icon,
.taskInfo-mainTitle-left-avatar {
  width: 33px;
  height: 33px;
  margin-right: 9px;
  cursor: pointer;
}

.taskInfo-mainTitle-left-icon {
  width: 27px;
  height: 27px;
}

.taskInfo-mainTitle-left-avatar {
  border-radius: 50%;
  overflow: hidden;
}

.taskInfo-mainTitle-left-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.taskInfo-mainTitle-right {
  height: 100%;
  display: flex;
  align-items: center;
}

.taskInfo-mainTitle-right-icon {
  width: 20px;
  height: 100%;
  margin-left: 11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.taskInfo-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.taskInfo-title {
  width: calc(100% - 5px);
  margin: 15px 0px 20px 2.5px;
  border: 0px;
  font-size: 18px;
  padding: 5px;
  box-sizing: border-box;
}

.taskInfo-item,
.taskInfo-path-item,
.taskInfo-auto-item {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  position: relative;
}

.taskInfo-item {
  height: 48px;
  line-height: 48px;
}

/* .taskInfo-Editor {
  border: 1px solid #efefef;
  padding: 2px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
} */
.taskInfo-Editor-mask {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
}

.taskInfo-Editor-img {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 16px;
  right: 13px;
  z-index: 5;
  cursor: pointer;
}

.taskInfo-item-title {
  width: 30px;
  height: 100%;
  margin-right: 12px;
}

.taskInfo-item-info,
.taskInfo-item-auto-info,
.taskInfo-item-follow,
.taskInfo-item-img-info {
  width: calc(100% - 42px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.taskInfo-item-auto-info>span {
  width: 100%;
}

.taskInfo-item-img-info {
  justify-content: flex-start;
}

.taskInfo-item-img-preview {
  /* width: 70px;
  height: 70px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; */
  margin-right: 5px;
  position: relative;
  z-index: 1
}

.taskInfo-item-img-preview:hover .taskInfo-item-img-delete {
  display: block;
}

.taskInfo-item-img-preview .taskInfo-item-img-delete {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  display: none;
}

.taskInfo-item-suggest {
  /* width: 36px; */
  height: 36px;
  border-radius: 18px;
  padding: 0px 8px;
  display: flex;
  line-height: 36px;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  z-index: 10;
  margin-left: 7px;
}

.taskInfo-item-suggest-item {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.taskInfo-item-hour,
.taskInfo-item-countdown {
  width: 20px;
  height: 100%;
  /* background: #999;
  border-radius: 4px;
  color: #fff;*/
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 14px;  */
  position: relative;
  cursor: pointer;
}

.taskInfo-item-hour img,
.taskInfo-item-countdown img {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.taskInfo-item-follow {
  width: calc(100% - 42px);
  min-height: 48px;
  background: #ebebeb;
  border: 1px solid rgba(235, 235, 235, 1);
  border-radius: 4px;
}

.taskInfo-comment {
  margin: 15px 0px 65px 0px;
}

.taskInfo-comment-tabs {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  line-height: 32px;
  border-bottom: 1px solid #d3d3d3;
  box-sizing: border-box;
}

.taskInfo-comment-tabs-item {
  height: 32px;
  text-align: center;
  cursor: pointer;
  padding: 0px 8px;
}

.taskInfo-comment-tabs-item:nth-child(1) {
  margin-right: 34px;
}

.taskInfo-comment-tab {
  width: 100%;
  height: calc(100vh - 420px);
  overflow: auto;
  padding-top: 15px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

.taskInfo-comment-tab::-webkit-scrollbar,
.taskInfo-container::-webkit-scrollbar {
  width: 0px;
}

.taskInfo-comment-tab::-webkit-scrollbar-thumb,
.taskInfo-container::-webkit-scrollbar-thum {
  background: #47475b;
  border-radius: 100px;
}

.taskInfo-comment-historyLog {
  width: 100%;
  font-size: 12px;
  color: #8091a0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taskInfo-comment-title {
  width: calc(100% - 65px);
  display: flex;
  align-items: center;
}

.taskInfo-comment-avatar {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 50%;
}

.taskInfo-comment-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.taskInfo-comment-info {
  width: calc(100% - 45px);
  height: 100%;
  min-height: 50px;
  border-bottom: 1px solid #f0f0f0;
}

.taskInfo-comment-input {
  /* width: 427px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  background-color: #fff;
  z-index: 10;
  margin-right: 3px
}

.point {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #8091a0;
  margin-right: 5px;
}

.editor-button {
  background-color: #1890ff;
  color: #fff;
  position: absolute;
  bottom: -105px;
  right: 0px;
  z-index: 30;
}

/* .taskInfo [contenteditable]:focus {
  outline: none;
} */
.taskInfo .addTask-item {
  width: 100%;
  justify-content: space-between;
}

.taskInfo-item-input {
  width: calc(100% - 72px);
  height: 36px;
  border: 1px solid #cecece;
  border-radius: 6px;
  padding: 0px 8px;
  box-sizing: border-box;
}

.taskInfo-item-url {
  width: 30px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-button {
  position: absolute;
  right: 65px;
  bottom: 50px;
  z-index: 20;
}

.comment-info {
  width: 100%;
  position: absolute;
  left: 0px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px -3px 10px #e4e3e3;
}

.taskInfo::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.taskInfo-container::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.taskInfo .w-e-toolbar {
  z-index: 1 !important;
}

.taskInfo .w-e-text-container {
  z-index: 0 !important;
}

.taskInfo .ant-timeline-item-last>.ant-timeline-item-content {
  min-height: 15px !important;
}

.taskInfo .ant-timeline-item {
  padding-bottom: 15px !important;
  padding-left: 8px !important;
}

.taskInfo .ant-timeline-item-head-custom {
  left: 15px !important;
}

.taskInfo-path-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.taskInfo-path {
  width: 100%;
  font-size: 8px;
  /* transform: scale(0.9); */
  text-align: left;
  /* margin-left:-20px; */
  /* color: #333; */
  /* display: flex;
  flex-wrap: wrap; */
}

.taskInfo-path>span {
  cursor: pointer;
}

.taskInfo .ant-image-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}