.diary {
  position: relative;
  height: 100%;
  width: 100%;
  color: #333;
}

.diary-list {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
  padding: 0.5% 10px;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  align-content: flex-start;
}

.diary-pagination {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.diary-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.diary-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.diary-item {
  width: 24%;
  height: 90px;
  margin-right: 1%;
  background: #fff;
  color: #333;
  padding: 13px 18px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 5px;
}

.diary-info,
.diary-comment,
.diary-comment-button {
  width: 100%;
  padding: 37px 0px;
  position: relative;
  color: #333;
}

.diary-info-close {
  position: absolute;
  font-size: 25px;
  right: 30px;
  top: 15px;
  z-index: 20;
  color: #333;
}

.diary-title {
  width: 100%;
  height: 50%;
  font-size: 12px;
}

.diary-icon {
  width: 100%;
  height: 50%;
  font-size: 12px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.diary-icon-clickNumber,
.diary-icon-isLike {
  display: flex;
  align-items: center;
}

.diary-icon-clickNumber img {
  width: 19px;
  height: 13px;
  margin-right: 5px;
}

.diary-icon-isLike img {
  width: 16px;
  height: 16px;
  margin: 0px 5px;
}

.diary-item:hover {
  box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.26);
}

.diary-content-title {
  width: 100%;
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  line-height: 28px;
  text-align: center;
  margin-bottom: 8px;
}

.diary p,
.diary-content-pn {
  width: calc(100% - 40px);
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin-left: 30px;
  padding: 5px 0px 5px 20px;
  box-sizing: border-box;
  background: rgba(249, 249, 249, 1);
  margin-bottom: 0px;
}

.diary-content-pn {
  padding: 0px;
  border: 1px solid rgba(232, 232, 232, 1);
}

.diary-content-tab {
  height: 40px;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  display: flex;
  line-height: 40px;
}

.diary-content-info {
  min-height: 88px;
  line-height: 88px;
  display: flex;
}

.diary-content-tab>div,
.diary-content-info>div {
  width: 50%;
  border-left: 1px solid rgba(232, 232, 232, 1);
  padding-left: 5px;
  box-sizing: border-box;
}

.diary-content-tab>div:nth-child(1),
.diary-content-info>div:nth-child(1) {
  border-left: 0px;
}

.diary h2 {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
}

.diary h3 {
  width: calc(100% - 40px);
  height: 43px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(64, 74, 82, 1);
  line-height: 33px;
  margin-left: 30px;
  padding: 5px 0px 5px 10px;
  box-sizing: border-box;
  background: rgba(249, 249, 249, 1);
  margin-bottom: 0px;
}

.diary-comment-title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: rgba(127, 127, 127, 1);
}

.diary-comment-icon {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
}

.diary-comment-icon img {
  width: 16px;
  height: 15px;
  margin-right: 5px;
}

.diary-comment-like {
  width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: rgba(79, 142, 242, 1);
}

.diary-comment-like img {
  width: 16px;
  height: 16px;
  margin: 0px 5px;
  cursor: pointer;
}

.diary-comment-info {
  width: 100%;
  max-height: 610px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.diary-comment-item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.diary-comment-item-avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.diary-comment-item-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.diary-comment-item-info {
  flex: 1;
  margin-left: 12px;
}

.diary-comment-item-nickName {
  font-size: 14px;
  font-weight: 400;
  color: rgba(79, 142, 242, 1);
}

.diary-comment-item-content {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}

.diary-comment-item-reply {
  width: 60px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.diary-comment-reply-icon {
  width: 18px;
  height: 12px;
  margin-right: 5px;
}

.diary-comment-delete-icon {
  width: 15px;
  height: 16px;
  margin-right: 5px;
}

.diary-comment-reply-icon img,
.diary-comment-delete-icon img {
  width: 100%;
  height: 100%;
}

.diary-comment-reply-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(79, 142, 242, 1);
}

.diary-comment-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 37px 20px 37px 0px;
}

.diary-menu {
  width: 350px;
  height: 100%;
  padding: 0px 15px;
  box-sizing: border-box;
}

.diary-menu-container {
  height: calc(100% - 43px);
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.diary-menu-item,
.diary-menu-title {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  line-height: 30px;
  cursor: pointer;
}

.diary-menu-title {
  height: 40px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: bold;
}

.diary-menu-item span,
.diary-menu-title span {
  width: 140px;
  text-align: center;
}

.diary-menu-item span:nth-child(1),
.diary-menu-title span:nth-child(1){
  width: 95px;
}
.diary-menu-item span:nth-child(2),
.diary-menu-title span:nth-child(2) {
  width: 45px;
}

.diary-container {
  /* width: 70%; */
  height: 100%;
  padding-right: 15px;
  box-sizing: border-box;
  flex: 1;
}

.diary-container-title {
  width: 100%;
  height: 25px;
  line-height: 25px;
  padding-left: 10px;
  box-sizing: border-box;
}

.diary-container-item {
  padding-left: 20px;
  box-sizing: border-box;
}

.diary-person {
  position: fixed;
  top: 60px;
  right: 25px;
  z-index: 3;
}

.diary-avatar {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.diary-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.diary-avatar:hover {
  background-color: #f0f0f0;
}

.diary-content-textarea {
  width: 50%;
  height: 200px;
  border-radius: 5px;
  border: 0px;
  padding: 8px;
  box-sizing: border-box;
}

.diary-textarea {
  width: calc(100% - 30px);
  height: 200px;
  border-radius: 5px;
  border: 1px solid rgba(232, 232, 232, 1);
  margin-left: 30px;
  padding: 8px;
  box-sizing: border-box;
}

.diary-textarea:focus,
.diary-content-textarea:focus {
  outline: 0;
}

.save-button {
  color: #fff;
  margin-left: 10px;
  height: 28px;
  line-height: 18px;
}

.diary a {
  color: #333;
  /* display: block; */
  text-decoration: none;
}

/*正常的未被访问过的链接*/
.diary a:link {
  color: #333;
  text-decoration: none;
}

/*已经访问过的链接*/
.diary a:visited {
  color: #333;
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
.diary a:hover {
  color: #333;
  text-decoration: none;
  background-color: #f0f0f0;
}

/* 正在点击的链接，鼠标在元素上按下还没有松开*/
.diary a:active {
  color: #333;
  text-decoration: none;
}

/* 获得焦点的时候 鼠标松开时显示的颜色*/
.diary a:focus {
  color: #333;
  text-decoration: none;
}

.diaryall-title {
  width: 100%;
  height: 39px;
  background: #f1f1f1;
  border-radius: 4px;
  line-height: 39px;
}

.diary .diaryall-subtitle {
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 30px;
  margin: 13px 0px;
  display: flex;
  align-items: center;
}

.diaryall-subtitle-img {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
}

.diaryall-subtitle-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.diary-comment-input {
  width: 90%;
  height: 36px;
  border-radius: 6px;
}

.diaryall-a {
  visibility: hidden;
}

.diary-container-mainTitle {
  width: 100%;
  height: 39px;
  background: #e3e3e3;
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
}

.diary-container-mainTitle>div {
  display: flex;
  align-items: center;
}

.diary-member {
  width: 50px;
  /* height: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  overflow: hidden;
  padding: 5px 0px;
  box-sizing: border-box;
  background: #333333;
  position: fixed;
  top: 68px;
  left: calc(100% - 50px);
  max-height: calc(100vh - 68px);
  z-index: 5;
  overflow: auto;
}

.diary-logo {
  width: 35px;
  height: 32px;
  margin-bottom: 10px;
  cursor: pointer;
}

.diary-month {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.diary-container-box {
  width: 100%;
  height: calc(100% - 40px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@keyframes rightTop {
  from {
    height: 100%;
  }

  to {
    height: 40px;
  }
}

@keyframes rightBottom {
  from {
    height: 40px;
  }

  to {
    height: 100%;
  }
}