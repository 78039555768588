.commentItem {
  width: 100%;
  min-height: 50px;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
}
.commentItem-avatar {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.commentItem-content {
  width: calc(100% - 80px);
  display: flex;
  flex-wrap: wrap;
}
.commentItem-content-title {
  width: 100%;
  height: 20px;
  margin-bottom: 5px;
  line-height: 20px;
  display: flex;
}
.commentItem-content-name {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin-right: 5px;
}
.commentItem-content-time {
  font-size: 10px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 26x;
}
.commentItem-content-info {
  width: 100%;
  min-height: 25px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  display: flex;
  align-content: center;
}
.commentItem-content-button {
  width: 30px;
  height: 100%;
  color: #1890ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}
