@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

* {
  padding: 0px;
  margin: 0px;
}

*::-webkit-scrollbar {
  width: 1.5px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #1890ff;
  border-radius: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dropDown-box {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  padding: 10px;
  box-sizing: border-box;
}

.autoImg>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clear {
  clear: both;
  height: 0;
  /*解决IE6下有高度的问题*/
  overflow: hidden;
}

g[filter] {
  display: none;
}
.ant-drawer-header{
  padding:10px 5px !important;
}