.contentModal {
    width:350px;
    height: 250px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 50;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
    background-color: #fdf1ce;
    padding: 10px 12px;
    border-radius: 12px;
}

.contentModal-title {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
}

.contentModal-title-left {
    width: 100%;
    display: flex;
    align-items: center;
}

/* .contentModal-title-left {
    width: calc(100% - 140px)
} */

.contentModal-title-left-title {
    margin-left: 8px;
    font-size: 16px;
    font-weight: bold;
}

.contentModal-content {
    width: 100%;
    height: calc(100% - 75px);
    display: flex;
    justify-content: space-between;
}

.contentModal-content-left {
    width: 55%;
    height:190px;
    padding-top: 10px;
    box-sizing: border-box;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
}
.contentModal-content-left>div{
    width: 100%;
}

.contentModal-content-left>div:nth-child(1),
.contentModal-content-left-title {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
}

.contentModal-content-left .contentModal-title-right {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.contentModal-content-left .contentModal-content-left-hour {
    font-size: 40px;
    height: 75px
}

.contentModal-content-left .contentModal-content-left-chart {
    justify-content: center;
    height: calc(100% - 180px)
}

.contentModal-content-left-title {
    display: flex;
    justify-content: center;
    color: #1890ff;
}

.contentModal-content-right {
    width: 45%;
}

.contentModal-content-right-title {
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-bottom: 1px solid #f0f0f0;
}

.contentModal-content-right-task {
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto;
    margin-top: 10px;
}

.contentModal-content-icon {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentModal-content-icon-text {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    align-items: flex-end;
    font-size: 36px;
    padding-bottom: 30px;
    box-sizing: border-box;
    color: #fff;
}