.dropMenu {
  position: absolute;
  left: 0px;
  z-index: 5;
  background-color: #fff;
  overflow: auto;
-webkit-overflow-scrolling:touch;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  font-weight: 500;
  box-sizing: border-box;
  color: #333;
}
.dropMenu-title {
  width: 100%;
  height: 53px;
  border-bottom: 1px solid #f0f0f0;
  padding: 0px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropMenu-info {
  width: 100%;
  /* padding: 12px 0px; */
  /* box-sizing: border-box; */
  overflow: auto;
-webkit-overflow-scrolling:touch;
}
.dropMenu-item {
  height: 38px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 17px;
  box-sizing: border-box;
  cursor: pointer;
}
.dropMenu-item:hover {
  background-color: #f0f0f0;
}
