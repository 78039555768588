.clockIn {
  width: 100%;
  height: 100%;
}

.clockIn-mainTitle {
  width: 100%;
  height: 53px;
  line-height: 53px;
  border-bottom: 1px solid #f0f0f0;
  padding: 0px 16px;
  box-sizing: border-box;
}

.clockIn-button {
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clockIn-textarea {
  width: 100%;
  /* height: 200px; */
  height: calc(100% - 30px);
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;
}

.clockIn-textarea:focus {
  outline: 0;
}

.clockIn-logo {
  width: 12px;
  height: 7px;
  margin-left: 10px;
}

.clockIn-title {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
}

.clockIn-info-first {
  height: calc((100% - 98px)*0.618);
}
.clockIn-info-second  {
  height: calc((100% - 98px)*0.228);
}
.clockIn-info-third {
  height: calc((100% - 98px)*0.152);
}
.clockIn-info-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
}

.clockIn-title-logo,
.clockInGroup-item-logo {
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 6px;
  margin: 0px 10px;
  flex-shrink: 0;
}

.clockIn-title-logo img,
.clockInGroup-item-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clockInGroup {
  padding: 15px 0px;
  box-sizing: border-box;
}

.clockInGroup-item {
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.clockInGroup-item:hover {
  background-color: #f0f0f0;
}

.clockInGroup-item-logo {
  width: 24px;
  height: 24px;
}

.clockIn-report {
  display: flex;
  margin-right: 10px;
  cursor: pointer;
}

.clockInGroup-item-name {
  width: calc(100% - 34px);
  height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  line-height: 38px;
}

.clockIn-drop {
  height: 500px;
  z-index: 20;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}