.avatar-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.avatar-img {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  object-fit: cover;
}